const ordered = [
  "DEMOGRAPHICS",
  "Age",
  "Border Crossing Frequency",
  "Border Location",
  "Country of Residence",
  "Education Level",
  "Gender",
  "Preferred Language",
  "Occupation",
  "Grow/Produce their Own Goods",
  "Smartphone Access",
  "TRADE INSIGHTS",
  "Currency Exchanges",
  "Traders' Destination Country",
  "Traders' Destination Market",
  "Traded Commodities' Origin",
  "Traded Commodities",
  "Traded Commodity Categories",
  "BUSINESS INSIGHTS",
  "Relevant Business Info",
  "Relevant Agencies",
  "Relevant Trade Documents",
  "Relevant Trade Procedures, by Commodity",
  "Relevant Trade Procedures, by Commodity Category",
  "Relevant Trade Procedures, by Destination"
];
const allowed = [
  "Age",
  "Country of Residence",
  "Education Level",
  "Relevant Agencies",
  "Relevant Trade Procedures, by Destination",
  "Traded Commodity Categories"
];

export { ordered, allowed };
