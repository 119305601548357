import React from "react";
import { ContentContainer } from "../../dashboard/styledComponents/Index";
import styled from "styled-components";

export const Div = styled.div`
  width: 500px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  text-align: center;
  border-radius: 10px;
`;
export const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 3rem;
`;

const BigX = styled.big`
  margin-left: 420px;
  font-size: 2.5rem;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: red;
  }
`;

function ValueNotSelectedModal({ noValue, setNoValue, filters, setFilters }) {
  const emptyFilter = {
    nameOfFilter: "Data Filter",
    selectedCategory: "",
    selectableOptions: {},
    selectedTable: "",
    selectedTableColumnName: "",
    showOptions: true,
    optionHasBeenSelected: false
  };

  const removeFilter = () => {
    for (let item in filters) {
      if (filters[item].selectedCategory === noValue) {
        filters[item] = emptyFilter;
      }
    }
    setFilters(filters);
    setNoValue();
  };

  return (
    <ContentContainer>
      <div>
        <Div>
          <BigX onClick={() => setNoValue()}>X</BigX>
          <FormTitle>
            Select a value for Filter: <strong>{noValue}</strong>
          </FormTitle>
          <p>or</p>
          <button onClick={removeFilter}>
            Clear Filter: <strong>{noValue}</strong>
          </button>
        </Div>
      </div>
    </ContentContainer>
  );
}

export default ValueNotSelectedModal;
