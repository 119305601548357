//get the total periods in time and an array of all periods
//adjusts the numbers displayed above the slider
function getRangePeriods(time, allPeriodsArray) {
  let periodsAmount = time.length;
  for (let i = 0; i < time.length; i++) {
    let item = time[i];
    for (let key in item) {
      if (key === "date") {
        allPeriodsArray.push(item[key]);
      }
    }
  }
  return { allPeriodsArray, periodsAmount };
}

function swapValues(valuesArray, left, right) {
  let originalLeft = valuesArray[left];
  valuesArray[left] = valuesArray[right];
  valuesArray[right] = originalLeft;
}

function findUnOrdered(valuesArray, left, right) {
  let pivot = Number(
    valuesArray[Math.floor((right + left) / 2)]["date"].replace("-", "")
  );

  while (left <= right) {
    while (Number(valuesArray[left]["date"].replace("-", "")) < pivot) {
      left++;
    }
    while (Number(valuesArray[right]["date"].replace("-", "")) > pivot) {
      right--;
    }
    if (left <= right) {
      swapValues(valuesArray, left, right);
      left++;
      right--;
    }
  }
  return left;
}
// O(n log(n))
function quickSort(valuesArray, left, right) {
  let pointer;
  if (valuesArray.length > 1) {
    pointer = findUnOrdered(valuesArray, left, right);

    if (left < pointer - 1) {
      quickSort(valuesArray, left, pointer - 1);
    }

    if (pointer < right) {
      quickSort(valuesArray, pointer, right);
    }
  }
  return valuesArray;
}
export { getRangePeriods, quickSort };
