import { translateSql } from "../utils/categories/translateSql";

const setItem = (data, dataStructure, indexBy, graphLabels, labelItems) => {
  let arr = [];
  dataStructure.forEach(obj => arr.push(Object.values(obj)[0]));
  // if weighted will sum up weighted values
  // if (data && data[0]?.sum) {
  arr.forEach((key, index) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i][translateSql[indexBy]] === key) {
        let add = Number(data[i].sum);
        total = total + add;
      }
    }
    total = total.toFixed(2);
    dataStructure[index] = {
      ...dataStructure[index],
      [`${arr[index]}`]: total
    };
  });

  let sampleSize = 0;

  dataStructure.map(item => {
    const keyValue = item[`${indexBy}`];
    return (sampleSize += Number(item[keyValue]));
  });
  let percentageData = dataStructure.map(obj => Object.assign({}, obj));
  percentageData.forEach(obj => {
    const keyValue = obj[`${indexBy}`];
    obj[keyValue] = ((obj[keyValue] / sampleSize) * 100).toFixed(1);
  });

  const catToSort = ["age", "education", "crossing_freq"];
  if (!catToSort.includes(indexBy)) {
    percentageData = percentageData.sort((a, b) =>
      Number(Object.values(a)[1]) > Number(Object.values(b)[1]) ? -1 : 1
    );
    dataStructure = dataStructure.sort((a, b) =>
      Number(Object.values(a)[1]) > Number(Object.values(b)[1]) ? -1 : 1
    );
  }

  return {
    dataStructure,
    percentageData,
    keys: labelItems ? labelItems : graphLabels[`${indexBy}`].labels,
    indexBy,
    sampleSize
  };
};

export default setItem;
