import React from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const RedSwitch = withStyles({
  switchBase: {
    color: "#546e7a",
    "&$checked": {
      color: "#9F1C0F"
    },
    "&$checked + $track": {
      backgroundColor: "#9F1C0F"
    }
  },
  checked: {},
  track: {}
})(Switch);

const useStyles = makeStyles(theme => ({
  section: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white"
  }
}));
export default function WeightedToggle({ useWeighted, setUseWeighted }) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <FormControlLabel
        label="Weighted Data"
        control={
          <RedSwitch
            checked={useWeighted}
            onChange={() => setUseWeighted(!useWeighted)}
          />
        }
      />
    </div>
  );
}
