import { quickSort } from "../../LineGraphHelpers/Range";

function combineAmountsToQtr(o) {
  let dateCatQtr = [];
  let dateCatArrayQtr = [];
  // change quarter month
  for (let key of Object.keys(o)) {
    let year = key.slice(0, 4);
    let qtr = key.slice(5, 7);
    //qtr = Number(qtr);
    if (qtr === "Q1") {
      qtr = "03";
    } else if (qtr === "Q2") {
      qtr = "06";
    } else if (qtr === "Q3") {
      qtr = "09";
    } else {
      qtr = "12";
    }
    let cat = key.slice(7, 100);
    let obj = {};
    obj["date"] = `${year}-${qtr}`;
    obj[cat] = o[key];

    dateCatArrayQtr.push(obj);
  }
  // quarters were converted to months, for the sort function
  dateCatArrayQtr = quickSort(dateCatArrayQtr, 0, dateCatArrayQtr.length - 1);
  // convert months to quarters
  for (let i = 0; i < dateCatArrayQtr.length; i++) {
    let yr = dateCatArrayQtr[i].date.slice(0, 4);
    let mth = dateCatArrayQtr[i].date.slice(5, 7);
    let item = { ...dateCatArrayQtr[i] };
    let month;
    if (mth === "03") {
      month = "Q1";
    } else if (mth === "06") {
      month = "Q2";
    } else if (mth === "09") {
      month = "Q3";
    } else {
      month = "Q4";
    }
    item.date = `${yr}-${month}`;
    dateCatQtr.push(item);
  }
  return dateCatQtr;
}

export { combineAmountsToQtr };
