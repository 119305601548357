import React, { useState, useEffect } from "react";
import { Event } from "../dashboard/GoogleAnalytics/index";
import { getSelectedOption } from "../OptionFunctions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { barDownload } from "../Components/redux-actions/barDownloadAction";
import dynamicText from "./dynamicText";
import { applyAction } from "../Components/redux-actions/applyAction";
import { selectedFiltersAction } from "./redux-actions/selectedFiltersAction";
import { chartDataAction } from "./redux-actions/chartDataAction";
import { makeStyles } from "@material-ui/core/styles";
import { numberWithComma } from "../utils/categories/translateSql";
import { translateSql } from "../utils/categories/translateSql";
import { dateAction } from "./redux-actions/dateAction";
import BarGraphDisplay from "./BarGraphDisplay";

const Graph = ({
  data,
  csvData,
  filters,
  keys,
  groupMode,
  sampleSize,
  chartData,
  uniquePhones
}) => {
  const seven = data => {
    if (filters["1"]["selectedCategory"] !== "") {
      let sevenGroup = [];
      const maxLength = data.length > 6 ? 7 : data.length;
      for (let i = 0; i < maxLength; i++) {
        let group = data[i];
        let topArr = [];
        for (let item in group) {
          if (typeof group[item] === "number") {
            topArr.push(group[item]);
          }
        }
        topArr = topArr.sort((a, b) => b - a);
        if (topArr.length > 6) {
          let sevenObj = {};
          for (let item in group) {
            if (typeof group[item] !== "number") {
              sevenObj[item] = group[item];
            } else if (group[item] >= topArr[6]) {
              sevenObj[item] = group[item];
            }
          }
          sevenGroup.push(sevenObj);
        } else {
          return data.slice(0, 6);
        }
      }
      if (sevenGroup.length > 0) {
        return sevenGroup;
      }
    }
    return data;
  };

  const keysWithCrossFilter = () => {
    let arr = [];
    if (filters[1].selectedCategory !== "") {
      for (let obj of displaySeven) {
        let items = Object.keys(obj);
        items.shift();
        arr = [arr, ...items];
      }
      return arr;
    } else {
      return keys;
    }
  };

  const dispatch = useDispatch();
  const [chartDataSM, setChartDataSM] = useState([]);
  const [displaySeven, setDisplaySeven] = useState(seven(data));
  const [keyArr, setKeyArr] = useState();
  const sampleRounded = Math.round(sampleSize);
  let dyText = "";
  for (let key in dynamicText) {
    if (filters[0]["selectedCategory"] === key) {
      dyText = dynamicText[key];
    }
  }
  const setApplyReducer = useSelector(state => state.setApplyReducer);
  const updateDate = useSelector(state => state.dateActionReducer.updateDate);
  let applyNow = setApplyReducer.applyNow;
  const setApplyNow = setApplyReducer.setApplyNow;

  useEffect(() => {
    if (updateDate) setDisplaySeven(seven(data));
    dispatch(dateAction(false));
  }, [updateDate]);

  const [csvDownload, setCsvDownload] = useState([]);

  const makeValues = data => {
    return data?.map(obj => {
      let catName = Object.keys(obj)[0];
      let item = obj[catName];
      if (item) obj[item] = Number(obj[item]).toFixed(2);
      return Object.values(obj);
    });
  };

  let keysColumn = [];
  let crossRows = [];

  const crossMakeColumns = (data, varAddFilters) => {
    let crossRowVals = Object.keys(filters[1].selectableOptions);
    for (let i = 0; i < crossRowVals.length; i++) {
      crossRows.push([crossRowVals[i]]);
    }

    let obj1 = {};
    obj1["id"] = `${0}`;
    obj1["displayName"] = filters[1].selectedTableColumnName;
    keysColumn.push(obj1);

    for (let i = 0; i < data.length; i++) {
      for (let k = 0; k < crossRows.length; k++) {
        let val = data[i][crossRows[k][0]];
        if (data[i][crossRows[k][0]] === undefined) val = "0";
        crossRows[k].push(val);
      }
      let obj = {};
      obj["id"] = `${i + 1}`;
      obj["displayName"] =
        data[i][translateSql[filters[0].selectedTableColumnName]];
      keysColumn.push(obj);
    }
    let header = {};
    header["id"] = `${68}`;
    header[
      "displayName"
    ] = `Sample Size: ${sampleRounded} /  Number of Users: ${uniquePhones?.phones}`;
    let varCompare = {
      id: `${70}`,
      displayName: `Compare Data: ${compareBy()}  ${varAddFilters}`
    };
    keysColumn.push(header);
    //keysColumn.push(varCompare)  //add to show compare filter
  };

  const AddFilters = () => {
    let arr = [];
    for (let key in filters) {
      if (key > 1 && filters[key]["selectedCategory"] !== "") {
        let choices = filters[key]["selectableOptions"];
        let cat = filters[key]["selectedCategory"];
        let sel;
        for (let item in filters[key]["selectableOptions"]) {
          if (choices[item] === true) {
            sel = item;
          }
        }
        arr.push(` ${cat}:${sel} `);
      }
    }
    return arr;
  };

  const compareBy = () => {
    let arr = [];
    for (let key in filters) {
      if (key == 1) {
        let choices = filters[key]["selectableOptions"];
        let cat = filters[key]["selectedCategory"];
        let sel;
        for (let item in filters[key]["selectableOptions"]) {
          if (choices[item] === true) {
            sel = item;
          }
        }
        arr.push(`${cat}`);
      }
    }
    return arr;
  };

  const makeHeaders = data => {
    let varAddFilters = AddFilters();
    if (varAddFilters.length > 0) {
      varAddFilters = `Filters: ${varAddFilters}`;
    }
    let seriesName = filters[0].selectedTableColumnName;
    seriesName = seriesName.charAt(0).toUpperCase() + seriesName.slice(1);
    if (!filters[1].selectedCategory) {
      return [
        {
          id: `${0}`,
          displayName: `${seriesName}`
        },
        // instead of the subsample keys we put in the total count
        {
          id: `${1}`, // random value
          displayName: `Weighted Data`
        },
        {
          id: `${2}`, // random value
          displayName: `% of Weighted Data`
        },
        {
          id: `${68}`,
          displayName: `Weighted Data: ${sampleRounded} /  Sample Size: ${uniquePhones?.phones}`
        },
        {
          id: `${69}`,
          displayName: varAddFilters
        }
      ];
    } else {
      crossMakeColumns(csvDownload, varAddFilters);
      return [...keysColumn];
    }
  };

  // the download
  const csvFormater = data => {
    // the subsample case is messed up
    // if the user selected a subsample
    if (filters[1].selectedCategory.length > 0) {
      // clean up the duplicate rows containing dataItem[filters[0].selectedTableColumnName]] as a value
      let newData = [];
      let newDataCache = {};
      data.forEach(dataItem => {
        // if dataItem[filters[0].selectedTableColumnName] is in object.keys(newDataCache)
        // assume dataItem[filters[0].selectedTableColumnName] exists
        if (!newDataCache[dataItem[filters[0].selectedTableColumnName]]) {
          newData = [...newData, dataItem];

          newDataCache = {
            ...newDataCache,
            [dataItem[filters[0].selectedTableColumnName]]: 1
          };
        }
      });
      data = newData;
    }

    if (Object.keys(filters).length >= 2) {
      data = data.map(obj => {
        // calculate the additional filters
        let additionalCategories = {};
        Object.keys(filters)
          .filter(filterId => filterId >= 2)
          .forEach(filterId => {
            additionalCategories = {
              ...additionalCategories,
              [filters[filterId].selectedCategory]: getSelectedOption(
                filters,
                filterId
              )
            };
          });
        // case for the non subsamples
        if (filters[1].selectedCategory.length === 0) {
          return {
            ...obj, // all minus additional filters
            percentage: (
              (obj[obj[filters[0].selectedTableColumnName]] / sampleSize) *
              100
            ).toFixed(2)
            // ...additionalCategories // additional filters
          };
        } else {
          // the subsamples(filters[1]) don't have an item count for calculating percentages

          return {
            ...obj // all minus additional filters
            //...additionalCategories // additional filters
          };
        }
      });
    }
    return data;
  };

  let fileName = "";
  fileName = `${filters[0].selectedTableColumnName &&
    filters[0].selectedTableColumnName}${filters[1]?.selectedCategory &&
    "_by_" + filters[1]?.selectedCategory}${filters[2]?.selectedCategory &&
    `_where_${filters[2]?.selectedCategory}:(${
      Object.values({
        [filters[2]?.selectedCategory]: getSelectedOption(filters, 2)
      })[0]
    })`}`;

  let track = Event(fileName, "Downloaded Excel");

  useEffect(() => {
    setCsvDownload(csvFormater(csvData));
  }, [csvData]);
  const socialMediaLink = useHistory().location.search;
  useEffect(() => {
    dispatch(
      barDownload({
        makeValues: filters[1].selectedCategory
          ? crossRows
          : makeValues(csvDownload),
        columns: makeHeaders(csvDownload),
        fileName: fileName,
        suffix: `${new Date().toISOString()}`,
        track: track
      })
    );
  }, [makeValues, makeHeaders]);

  useEffect(() => {
    setChartDataSM(chartData);
    dispatch(
      chartDataAction({
        chart: chartData
      })
    );
  }, []);

  //fire apply button
  if (setApplyNow && applyNow) {
    dispatch(
      applyAction({
        apply: true
      })
    );
    // will change display of filters selected
    dispatch(
      selectedFiltersAction({
        selected: true
      })
    );
    setApplyNow(false);
  }

  return (
    <BarGraphDisplay
      displaySeven={displaySeven}
      keyArr={filters[1].selectedCategory === "" ? keys : keysWithCrossFilter()}
      selectedTableColumnName={translateSql[filters[0].selectedTableColumnName]}
      selectedCategory={filters[0].selectedCategory}
      groupMode={groupMode}
      uniquePhones={numberWithComma(uniquePhones?.phones)}
      dyText={dyText}
    />
  );
};

export default Graph;

const useStyles = makeStyles(theme => ({
  dyContainer: {
    width: "100%",
    padding: "0em 2em",
    fontSize: "1.0em",
    marginLeft: "-1px",
    borderLeft: "1px solid #00000045",
    "& h2": {
      fontWeight: 600,
      fontSize: "1.2em",
      paddingBottom: "10px"
    }
  }
}));
