import React from "react";
import dataParse from "./dataParse";
import Graph from "./Graph";
import { useSelector } from "react-redux";

const GraphParse = ({
  data,
  filters,
  queryType,
  setChartDataSM,
  uniquePhones
}) => {
  const graphLabels = useSelector(
    state => state.catLabelReducer.labels.getGraphLabels
  );
  //used on SocialMedia.js
  let chartData = dataParse(
    filters[0].selectedTableColumnName,
    data[`${queryType}`] || data.tradersUsers,
    filters[1]?.selectedTableColumnName,
    filters[2]?.selectedTableColumnName,
    filters[0].selectedTable,
    filters[1]?.selectedTable,
    graphLabels
  );
  chartData["users"] = uniquePhones;
  const graphItems = filters[1].selectedTableColumnName !== "";
  if (graphItems === true) {
    return (
      <>
        <Graph
          data={chartData.percentageData}
          csvData={chartData.dataStructure}
          filters={filters}
          keys={chartData.crossFilterValues}
          groupMode={"grouped"}
          sampleSize={chartData.totalSampleSize}
          setChartDataSM={setChartDataSM}
          chartData={chartData}
          uniquePhones={uniquePhones}
        />
      </>
    );
  } else if (chartData && graphItems === false) {
    return (
      <>
        <Graph
          data={chartData.percentageData}
          csvData={chartData.dataStructure}
          filters={filters}
          keys={chartData.keys || chartData.csvKeys}
          groupMode={"stacked"}
          sampleSize={chartData.sampleSize}
          setChartDataSM={setChartDataSM}
          chartData={chartData}
          uniquePhones={uniquePhones}
        />
      </>
    );
  } else {
    return <></>;
  }
};
export default GraphParse;
