export const FilterBoxOptions = {
  superCategories: [
    {
      label: "Demographics",
      options: [
        {
          label: "Age"
        },
        {
          label: "Border Crossing Frequency"
        },
        {
          label: "Border Location"
        },
        {
          label: "Country of Residence"
        },
        {
          label: "Education Level"
        },
        {
          label: "Gender"
        },
        {
          label: "Preferred Language"
        },
        {
          label: "Occupation"
        },
        {
          label: "Grow/Produce their Own Goods"
        },
        {
          label: "Smartphone Access"
        }
      ]
    },
    {
      label: "Trade Insights",
      options: [
        {
          label: "Relevant Business Info"
        },
        {
          label: "Relevant Agencies"
        },
        {
          label: "Relevant Trade Documents"
        },
        {
          label: "Relevant Trade Procedures, by Commodity"
        },
        {
          label: "Relevant Trade Procedures, by Commodity Category"
        },
        {
          label: "Relevant Trade Procedures, by Destination"
        }
      ]
    },
    {
      label: "Business Insights",
      options: [
        {
          label: "Currency Exchanges"
        },
        {
          label: "Traders' Destination Country"
        },
        {
          label: "Traders' Destination Market"
        },
        {
          label: "Traded Commodities' Origin"
        },
        {
          label: "Traded Commodities"
        },
        {
          label: "Traded Commodity Categories"
        }
      ]
    }
  ],
  default: {
    Gender: {
      value: { type: "gender", query: "Users" },
      subLabels: ["Male", "Female"]
    },
    "Education Level": { value: { type: "education", query: "Users" } },
    "Border Crossing Frequency": {
      value: { type: "crossingfreq", query: "Users" }
    },
    "Border Location": {
      value: { type: "border", query: "Users" }
    },
    Age: { value: { type: "age", query: "Users" } },
    "Country of Residence": {
      value: { type: "country_of_residence", query: "Users" }
    },
    Occupation: {
      value: { type: "occupation", query: "Users" }
    },
    "Preferred Language": { value: { type: "language", query: "Users" } },
    "Grow/Produce their Own Goods": {
      value: { type: "produce", query: "Users" }
    },
    "Smartphone Access": {
      value: { type: "whatsapp", query: "Users" }
    },
    "Relevant Trade Procedures, by Commodity": {
      value: { type: "procedurecommodity", query: "Sessions" }
    },
    "Relevant Trade Procedures, by Commodity Category": {
      value: { type: "procedurecommoditycat", query: "Sessions" }
    },
    "Relevant Trade Procedures, by Destination": {
      value: { type: "proceduredest", query: "Sessions" }
    },
    "Relevant Trade Documents": {
      value: { type: "procedurerequireddocument", query: "Sessions" }
    },
    "Relevant Agencies": {
      value: { type: "procedurerelevantagency", query: "Sessions" }
    },
    "Relevant Business Info": {
      value: { type: "infoArea", query: "Sessions" }
    },
    "Traded Commodities' Origin": {
      value: { type: "procedureorigin", query: "Sessions" }
    },
    "Traders' Destination Country": {
      value: { type: "commoditycountry", query: "Sessions" }
    },
    "Traders' Destination Market": {
      value: { type: "commoditymarket", query: "Sessions" }
    },
    "Traded Commodities": {
      value: { type: "commodityproduct", query: "Sessions" }
    },
    "Traded Commodity Categories": {
      value: { type: "commoditycat", query: "Sessions" }
    },
    "Currency Exchanges": {
      value: { type: "exchangedirection", query: "Sessions" }
    }
  },
  filtered: [
    { label: "Gender", value: { type: "gender", query: "Users" } },
    { label: "Education Level", value: { type: "education", query: "Users" } },
    {
      label: "Border Crossing Frequency",
      value: { type: "crossingfreq", query: "Users" }
    },
    {
      label: "Border Location",
      value: { type: "border", query: "Users" }
    },
    { label: "Age", value: { type: "age", query: "Users" } },
    {
      label: "Country of Residence",
      value: { type: "country_of_residence", query: "Users" }
    },
    {
      label: "Occupation",
      value: { type: "occupation", query: "Users" }
    },
    {
      label: "Preferred Language",
      value: { type: "language", query: "Users" }
    },
    {
      label: "Grow/Produce their Own Goods",
      value: { type: "produce", query: "Users" }
    },
    {
      label: "Smartphone Access",
      value: { type: "produce", query: "Users" }
    }
  ],
  tableNamesToCategoryName: {
    gender: "Gender",
    education: "Education Level",
    crossingfreq: "Border Crossing Frequency",
    border: "Border Location",
    age: "Age",
    country_of_residence: "Country of Residence",
    infoArea: "Relevant Business Info",
    occupation: "Occupation",
    language: "Preferred Language",
    produce: "Grow/Produce their Own Goods",
    procedurecommodity: "Relevant Trade Procedures, by Commodity",
    procedurecommoditycat: "Relevant Trade Procedures, by Commodity Category",
    proceduredest: "Relevant Trade Procedures, by Destination",
    procedurerequireddocument: "Relevant Trade Documents",
    procedurerelevantagency: "Relevant Agencies",
    procedureorigin: "Traded Commodities' Origin",
    commoditycountry: "Traders' Destination Country",
    commoditymarket: "Traders' Destination Market",
    commodityproduct: "Traded Commodities",
    commoditycat: "Traded Commodity Categories",
    exchangedirection: "Currency Exchanges",
    whatsapp: "Smartphone Access",
    smartphone_access: "Smartphone Access"
  }
};

export const translateUrl = {
  age: "age",
  border: "border",
  border_location: "border",
  crossing_location: "border",
  commoditycat: "commoditycat",
  commoditycountry: "commoditycountry",
  commoditymarket: "commoditymarket",
  commodityproduct: "commodityproduct",
  crossingfreq: "crossingfreq",
  crossing_freq: "crossingfreq",
  country_of_residence: "country_of_residence",
  education: "education",
  exchangedirection: "exchangedirection",
  infoArea: "infoArea",
  occupation: "occupation",
  primary_income: "primary_income",
  procedurecommodity: "procedurecommodity",
  procedurecommoditycat: "procedurecommoditycat",
  proceduredest: "proceduredest",
  procedurerelevantagency: "procedurerelevantagency",
  procedurerequireddocument: "procedurerequireddocument",
  produce: "produce",
  language: "language",
  gender: "gender",
  produce: "produce",
  procedureorigin: "procedureorigin",
  whatsapp: "smartphone-access"
};
