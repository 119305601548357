import { translateSql } from "../utils/categories/translateSql";
import { getSelectedOption } from "../OptionFunctions";

const quarter = qtr => {
  let result;
  if (qtr < 4) {
    result = "Q1";
  } else if (qtr < 7) {
    result = "Q2";
  } else if (qtr < 10) {
    result = "Q3";
  } else if (qtr < 13) {
    result = "Q4";
  }
  return result;
};

const countryCode = { "254": "KEN", "250": "RWA", "255": "TZA", "256": "UGA" };

const areaCode = country => {
  let result;
  if (country === "KEN") {
    result = "254";
  } else if (country === "RWA") {
    result = "250";
  } else if (country === "TZA") {
    result = "255";
  } else if (country === "UGA") {
    result = "256";
  }
  return result;
};

const filteredArray = (countryFilter, dataArray, filteredData, noData) => {
  const code = areaCode(countryFilter);
  const dataResult = [...dataArray];
  filteredData = dataResult.filter(item => {
    let area = item.cell_num_id.slice(0, 3);
    return area === code;
  });
  if (filteredData.length === 0) {
    noData();
  }
  return filteredData;
};

const convertWeighted = (
  arrayData,
  filters,
  setQueryType,
  noData,
  filterBoxStartDate,
  filterBoxEndDate
) => {
  let filteredData = [];
  let compareCat;
  let countryFilter;
  const allFilters = Object.keys(filters);
  for (let i = 0; i < allFilters.length; i++) {
    if (
      i > 1 &&
      filters[i].selectedTableColumnName === "country_of_residence"
    ) {
      countryFilter = getSelectedOption(filters, i);
    }

    if (i === 1) {
      compareCat = filters[1].selectedTableColumnName;
    }
  }
  if (countryFilter && arrayData) {
    filteredData = filteredArray(
      countryFilter,
      arrayData,
      filteredData,
      noData
    );
  }

  const createObj = (obj, objVal, threeDigits, sumValue) => {
    numUniquePhones[objVal["cell_num_id"]] = 1;

    const qtr = quarter(objVal.month);
    const month = objVal.month < 10 ? `0${objVal.month}` : objVal.month;
    let search = translateSql[filters[0].selectedCategory];
    obj["phone"] = threeDigits;
    obj[search] = objVal.value_name;
    obj["cell_num_id"] = objVal["cell_num_id"];
    obj["sum"] = sumValue;
    obj["date"] = `${objVal.year}${month}`;
    obj["created_mo"] = `${objVal.year}-${month}`;
    obj["created_qtr"] = `${objVal.year}-${qtr}`;
    obj["created_year"] = `${objVal.year}`;
    obj["__typename"] = "TraderUser";
    return obj;
  };

  let notNull = [];
  let values = countryFilter ? [...filteredData] : [...arrayData];
  setQueryType("tradersUsers");
  let numUniquePhones = {};
  if (translateSql[filters[0].selectedCategory] === "countryResidence") {
    for (let i = 0; i < values?.length; i++) {
      if (!numUniquePhones[values[i]["cell_num_id"]]) {
        numUniquePhones[values[i]["cell_num_id"]] = 1;
      }
      if (compareCat) {
        values[i][compareCat] = values[i].compare;
        values[i]["country_of_residence"] =
          countryCode[values[i]["cell_num_id"].slice(0, 3)];
      }
    }
    let phonesLength = Object.values(numUniquePhones).length;
    arrayData = [{ tradersUsers: values }, { phones: phonesLength }];
    return arrayData;
  }
  for (let i = 0; i < values?.length; i++) {
    let threeDigits = values[i]["cell_num_id"].slice(0, 3);
    let objVal = values[i];
    let sumValue = objVal.sum || objVal.cross_product;
    if (sumValue > 0) {
      if (countryCode[threeDigits]) {
        let obj = {};
        if ((filterBoxStartDate, filterBoxEndDate)) {
          const startDate = filterBoxStartDate.replace(/-/g, "");
          const endDate = filterBoxEndDate.replace(/-/g, "");
          const month =
            values[i].month < 10 ? `0${values[i].month}` : values[i].month;
          const yearMo = `${values[i].year}${month}`;
          if (yearMo > startDate && yearMo < endDate) {
            obj = createObj(obj, objVal, threeDigits, sumValue);
            if (compareCat) obj[compareCat] = objVal.compare;
            notNull.push(obj);
          }
        } else {
          obj = createObj(obj, objVal, threeDigits, sumValue);
          if (compareCat) obj[compareCat] = objVal.compare;
          notNull.push(obj);
        }
      }
    }
  }
  let phonesLength = Object.values(numUniquePhones).length;

  arrayData = [{ tradersUsers: notNull }, { phones: phonesLength }];
  return arrayData;
};

export { convertWeighted, countryCode };
