import React from "react";
import { getCurrentYear } from "../hooks/useCalendar";
import styled from "styled-components";

const YearButtons = ({ num, changeYear }) => {
  return (
    <>
      <YearButton onClick={changeYear((getCurrentYear() - num).toString())}>
        {(getCurrentYear() - num).toString()}
      </YearButton>
    </>
  );
};
export default YearButtons;

const YearButton = styled.button`
  padding: 5px;
  width: 25%;
  background-color: grey;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  border: 0.5px solid darkgrey;
  border-radius: 5px;
  opacity: 0.75;
  &:hover {
    opacity: 1
    cursor: pointer;
  }
`;
