const translateSql = {
  Age: "age",
  age: "age",
  border: "border",
  "Border Location": "border",
  border_location: "border",
  crossing_location: "border",
  "Border Crossing Frequency": "crossingfreq",
  commoditycat: "commoditycat",
  commoditycountry: "commoditycountry",
  commoditymarket: "commoditymarket",
  commodityproduct: "commodityproduct",
  crossingfreq: "crossingfreq",
  crossing_freq: "crossingfreq",
  "Cross Border Trade as Primary Income": "primary_income",
  "Country of Residence": "countryResidence",
  country_of_residence: "country_of_residence",
  "Currency Exchanges": "exchangedirection",
  "Education Level": "education",
  education: "education",
  exchangedirection: "exchangedirection",
  infoArea: "infoArea",
  Occupation: "occupation",
  occupation: "occupation",
  primary_income: "primary_income",
  procedurecommodity: "procedurecommodity",
  procedurecommoditycat: "procedurecommoditycat",
  proceduredest: "proceduredest",
  procedurerelevantagency: "procedurerelevantagency",
  procedurerequireddocument: "procedurerequireddocument",
  "Grow/Produce their Own Goods": "produce",
  produce: "produce",
  "Preferred Language": "language",
  language: "language",
  Gender: "gender",
  gender: "gender",
  "Grow/Produce their Own Goods": "produce",
  produce: "produce",
  procedureorigin: "procedureorigin",
  "Relevant Agencies": "procedurerelevantagency",
  "Relevant Business Info": "infoArea",
  "Relevant Trade Documents": "procedurerequireddocument",
  "Relevant Trade Procedures, by Commodity": "procedurecommodity",
  "Relevant Trade Procedures, by Commodity Category": "procedurecommoditycat",
  "Relevant Trade Procedures, by Destination": "proceduredest",
  "Smartphone Access": "whatsapp",
  "Traded Commodities": "commodityproduct",
  "Traded Commodity Categories": "commoditycat",
  "Traded Commodities' Origin": "procedureorigin",
  "Traders' Destination Country": "commoditycountry",
  "Traders' Destination Market": "commoditymarket",
  whatsapp: "whatsapp"
};

const numberWithComma = number => {
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  return internationalNumberFormat.format(number);
};

export { translateSql, numberWithComma };
