import { translateSql } from "../utils/categories/translateSql";

const sumWeighted = (data, dataStructure, indexBy) => {
  let arr = [];
  let newData = [];
  dataStructure.forEach(obj => arr.push(obj));
  let allTotal = 0;
  arr.forEach((key, index) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i][translateSql[indexBy]] === key) {
        if (typeof data[i].sum === "number") {
          total = total + data[i].sum;
          allTotal = allTotal + data[i].sum;
        }
      }
    }
    let obj = {};
    obj[`${translateSql[indexBy]}`] = arr[index];
    obj[`${arr[index]}`] = total;

    newData.push(obj);
  });
  return newData;
};

export { sumWeighted };
