import { getTodaysDate } from "../../../hooks/useCalendar";
// effects top 7 checked
const startEndDates = url => {
  let startEndDates = [];
  if (url.includes("start=")) {
    startEndDates = url.split("start=")[1].split("&end=");
  }
  const startYear = startEndDates[0]
    ? Number(startEndDates[0].slice(0, 4))
    : null;
  const endYear = startEndDates[1]
    ? Number(startEndDates[1].slice(0, 4))
    : null;

  if (
    startEndDates[0] !== "2017-01-01" &&
    startEndDates[1] !== getTodaysDate()
  ) {
  }

  return { startYear, endYear };
};
export default startEndDates;
