import { DATE_SUCCESS } from "../redux-actions/dateAction";

const initialState = {
  updateDate: false
};

const dateActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATE_SUCCESS:
      return {
        ...state,
        updateDate: action.payload
      };
    default:
      return state;
  }
};
export default dateActionReducer;
