import { corArray } from "../Components/helpers/labelsHardCode";

const countryOfResidenceArray = data => {
  let rwa = 0;
  let ken = 0;
  let tza = 0;
  let uga = 0;
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count += 1;
    let item = data[i].cell_num_id.slice(0, 3);
    switch (item) {
      case "250": // rwa
        rwa += 1;
        break;
      case "254": // ken
        ken += 1;
        break;
      case "255": // tza
        tza += 1;
        break;
      case "256": // uga
        uga += 1;
        break;
      default:
    }
  }
  corArray[0].sum = ken;
  corArray[1].sum = rwa;
  corArray[2].sum = tza;
  corArray[3].sum = uga;
  return corArray;
};

export { countryOfResidenceArray };
