import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import Loader from "react-loader-spinner";
import { getSelectedOption } from "../OptionFunctions";
import DisplaySelected from "./DisplaySelected";
import NoDataModal from "./Filters/clearFilters/NoDataModal";
import NotLoggedInModal from "./NotLoggedInModal";
import NoAccessModal from "./Filters/NoAccessModal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector, useDispatch } from "react-redux";
import { setApplyAction } from "../Components/redux-actions/setApplyAction";
import { convertWeighted } from "./queriesHelpers";
import { translateSql } from "../utils/categories/translateSql";

const GetData = (props, { makeValues }) => {
  const { filterBoxStartDate, filterBoxEndDate } = useSelector(
    state => state.calendarReducer.calendar
  );
  const queriesFilters = useSelector(
    state => state.queriesReducer.queriesFilters
  );

  const [applyNow, setApplyNow] = useState(false);

  const userTier = useSelector(state => state.tierReducer.tier);
  const noAccessReducer = useSelector(state => state.showNoAccessReducer.show);
  let noAccess = noAccessReducer.noAccess;
  const setNoAccess = noAccessReducer.setNoAccess;

  let queryType = props.queryType;
  let setQueryType = props.setQueryType;
  setQueryType("tradersUsers");
  let QUERY;
  let thisQuery;
  let filters;
  let setFilters = props.setFilters;

  if (queriesFilters.filters) {
    filters = queriesFilters.filters;
  } else if (filters === undefined) {
    setApplyNow(true);
    filters = props.filters;
    queriesFilters.filters = filters;
  }

  //dispatch ApplyNow
  const dispatch = useDispatch();
  dispatch(setApplyAction({ applyNow: applyNow, setApplyNow: setApplyNow }));

  if (filters[0]?.selectedCategory) {
    thisQuery = { data_key: translateSql[filters[0].selectedCategory] };
    if (translateSql[filters[1].selectedCategory])
      thisQuery["compare"] = translateSql[filters[1].selectedCategory];
    let weightedFiltersArray = [];
    Object.keys(filters).forEach(filterId => {
      if (filterId > 1 && filters[filterId].selectedTableColumnName) {
        const selectedFilter = `${
          filters[filterId].selectedTableColumnName
        }:${getSelectedOption(filters, filterId)}`;
        if (
          filters[filterId].selectedTableColumnName !== "country_of_residence"
        ) {
          weightedFiltersArray.push(selectedFilter);
        }
      }
    });
    if (weightedFiltersArray.length === 0) {
      setQueryType("weightedData");
      QUERY = gql`
        query getWeighted($queryTraders: newWeightedInput) {
          weightedData(input: $queryTraders) {
            cell_num_id
            value_name
            sum
            month
            year
            compare
          }
        }
      `;
    } else {
      thisQuery = { ...thisQuery };
      thisQuery["filters"] = weightedFiltersArray;
      QUERY = gql`
        query getWeightedFiltered($queryTraders: newWeightedFilteredInput) {
          weightedFiltered(input: $queryTraders) {
            cell_num_id
            year
            month
            sum_weight
            key_name
            value_name
            sum1
            sum2
            cross_product
            compare
          }
        }
      `;
    }
  } else if (translateSql[filters[0].selectedCategory] === "countryResidence") {
    setQueryType("weightedData");
    Object.keys(filters).forEach(filterId => {
      if (filterId !== 1) {
        // setting the data
        thisQuery = { data_key: translateSql[filters[0].selectedCategory] };
      }
    });
    // only need cell_num_id, function convertWeighted converts phone number
    QUERY = gql`
      query getWeighted($queryTraders: newWeightedInput) {
        weightedData(input: $queryTraders) {
          cell_num_id
        }
      }
    `;
  } else {
    // no filters
    setQueryType("weightedData");
    thisQuery = { data_key: translateSql[filters[0].selectedCategory] };
    QUERY = gql`
      query getWeighted($queryTraders: newWeightedInput) {
        weightedData(input: $queryTraders) {
          cell_num_id
          value_name
          sum
          month
          year
          compare
        }
      }
    `;
  }

  let { loading, data } = useQuery(QUERY, {
    variables: { queryTraders: thisQuery }
  });

  const [noDataModal, setNoDataModal] = useState(true);

  const selectedReducer = useSelector(
    state => state.selectedReducer.selected.selected
  );

  useEffect(() => {
    setNoDataModal(true);

    //so selected filters 'text' will not display until Apply pressed
    //selectedReducer is true if url is manually altered
    if (props.setSelectedFilters && selectedReducer != true) {
      props.setSelectedFilters(false);
    }
  }, [filters]);

  function noData() {
    if (noDataModal) {
      return (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={noDataModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade>
              <NoDataModal
                setNoDataModal={setNoDataModal}
                filters={filters}
                setFilters={setFilters}
                handleApply={props.handleApply}
              />
            </Fade>
          </Modal>
        </>
      );
    } else {
      return <></>;
    }
  }

  const [notLogged, setNotLogged] = useState(true);

  function notLoggedIn() {
    if (notLogged) {
      return (
        <NotLoggedInModal notLogged={notLogged} setNotLogged={setNotLogged} />
      );
    } else {
      return <></>;
    }
  }

  function NotAccessible() {
    if (noAccess) {
      return <NoAccessModal noAccess={noAccess} setNoAccess={setNoAccess} />;
    } else {
      return <></>;
    }
  }

  if (noAccess === true) {
    return NotAccessible();
  }

  if (userTier === undefined || userTier === "EXPIRED") {
    return notLoggedIn();
  }

  if (filters[1].selectedCategory === "" && data && data.tradersUsers) {
    const notNull = [];
    let values = data.tradersUsers;
    const selectedTableColumnName = filters[0].selectedTableColumnName;
    for (let i = 0; i < values.length; i++) {
      if (
        values[i][selectedTableColumnName] !== null &&
        values[i][selectedTableColumnName] !== ""
      ) {
        notNull.push(values[i]);
      }
    }
    data = { tradersUsers: notNull };
  }
  let uniquePhones;
  if (data) {
    let arrayData = data.weightedFiltered || data.weightedData;
    [data, uniquePhones] = convertWeighted(
      arrayData,
      filters,
      setQueryType,
      noData,
      filterBoxStartDate,
      filterBoxEndDate
    );
  }

  if (
    data &&
    data.tradersUsers !== undefined &&
    data.tradersUsers.length === 0 &&
    !loading
  ) {
    return noData();
  }

  if (
    data &&
    data.weightedFiltered !== undefined &&
    data.weightedFiltered.length === 0
  ) {
    return noData();
  }

  if (
    data &&
    data.weightedData !== undefined &&
    data.weightedData.length === 0 &&
    !loading
  ) {
    return noData();
  }

  if (loading || !data) {
    return (
      <div className="loader-container">
        <Loader
          className="loader"
          type="Oval"
          color="#708090"
          width={100}
          timeout={100000}
        />
      </div>
    );
  }
  return (
    <>
      <DisplaySelected
        filters={filters}
        queryType={queryType}
        setOpen={props.setOpen}
        data={data}
        setDisplayButton={props.setDisplayButton}
        displayButton={props.displayButton}
        setChartDataSM={props.setChartDataSM}
        uniquePhones={uniquePhones}
      />
    </>
  );
};

export default GetData;
