import getIndex from "../DataParseHelpers/getIndex";
import getMostRequested from "../DataParseHelpers/getMostRequested";
import setCrossedItems from "../DataParseHelpers/setCrossedItems";
import setItem from "../DataParseHelpers/setItem";
import { sumWeighted } from "../DataParseHelpers/sumWeighted";
import { labelsHardCode } from "./helpers/labelsHardCode";
import { translateSql } from "../utils/categories/translateSql";
import { countryOfResidenceArray } from "../DataParseHelpers/countryOfResidence";

const dataParse = (
  indexBy,
  data,
  crossFilter,
  additionalFilter,
  queryType,
  crossFilterQuery,
  graphLabels
) => {
  let labelItems = [];
  const hardCoded = ["age", "country_of_residence", "crossing_freq"];
  if (translateSql[indexBy]) indexBy = translateSql[indexBy];

  if (
    hardCoded.includes(indexBy) &&
    data &&
    (data[0].sum || data[0].cell_num_id)
  ) {
    labelItems = labelsHardCode[indexBy];
  } else {
    for (let i = 0; i < data?.length; i++) {
      if (data && data[0].sum) {
        if (!labelItems.includes(data[i][translateSql[indexBy]])) {
          labelItems.push(data[i][translateSql[indexBy]]);
        }
      } else {
        if (!labelItems.includes(data[i][indexBy])) {
          labelItems.push(data[i][indexBy]);
        }
      }
    }
    labelItems = labelItems.sort();
  }
  let dataStructure = [];
  //when single filtering "Most Requested" graph
  //remove multiples should happen prior to filterByDate
  //multiples should also be given a date
  if (data && queryType === "Sessions" && crossFilter === "") {
    if (data && data[0]?.sum) {
      dataStructure = [...labelItems];
      let newData = sumWeighted(data, dataStructure, indexBy);
      const weighted = true;
      return getMostRequested(data, newData, indexBy, weighted);
    } else {
      dataStructure = getIndex(data, indexBy);
      return getMostRequested(data, dataStructure, indexBy);
    }
  }
  //when cross-filtering "Most Requested" as index
  else if (data && queryType === "Sessions" && crossFilter !== "") {
    dataStructure = getIndex(data, indexBy);

    return setCrossedItems(
      data,
      dataStructure,
      crossFilter,
      indexBy,
      additionalFilter,
      queryType,
      crossFilterQuery,
      graphLabels
    );
  } else {
    if (queryType === "Users") {
      for (let i = 0; i < labelItems.length; i++) {
        let obj = {};
        obj[`${indexBy}`] = labelItems[i];
        dataStructure.push(obj);
      }
    }
    if (
      indexBy === "country_of_residence" &&
      data &&
      data[0]?.cell_num_id &&
      crossFilter === ""
    ) {
      data = countryOfResidenceArray(data);
    }
    if (crossFilter !== "") {
      return setCrossedItems(
        data,
        dataStructure,
        crossFilter,
        indexBy,
        additionalFilter,
        queryType,
        crossFilterQuery,
        labelItems
      );
    } else if (data) {
      return setItem(data, dataStructure, indexBy, graphLabels, labelItems);
    }
  }
};

export default dataParse;
