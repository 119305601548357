import React, { useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const BarGraphDisplay = ({
  displaySeven,
  keyArr,
  selectedTableColumnName,
  selectedCategory,
  groupMode,
  uniquePhones,
  dyText
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container style={{ height: "70vh" }}>
        <ResponsiveBar
          data={displaySeven}
          keys={keyArr}
          indexBy={selectedTableColumnName}
          groupMode={groupMode} // Possibly add toggle selector to change group mode.
          margin={{ top: 50, right: 170, bottom: 75, left: 80 }}
          padding={0.3}
          innerPadding={3}
          //maxValue={100}
          colors={{ scheme: "nivo" }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          tooltip={({ id, value }) => (
            <strong
              style={{
                color: "#000000",
                fontSize: "15px",
                fontFamily: "Helvetica"
              }}
            >
              {id}: {value}%
            </strong>
          )}
          labelFormat={d => (
            <tspan y={-15} key={d}>
              {d}%{" "}
            </tspan>
          )}
          labelForm={d => <text key={d}>{d}% </text>}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend:
              selectedCategory +
              " (values as percent of total)," +
              ` sample size = ${uniquePhones}`,
            legendPosition: "middle",
            legendDirection: "column",
            legendOffset: 35
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Percentage", // Possibly toggle percentage or number in future release
            legendPosition: "middle",
            legendOffset: -60
          }}
          labelSkipWidth={0}
          labelSkipHeight={0}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
        <Grid item className={classes.dyContainer}>
          <h2>Method Notes</h2>
          <div dangerouslySetInnerHTML={{ __html: dyText }}></div>
        </Grid>
      </Grid>
    </>
  );
};

export default BarGraphDisplay;

const useStyles = makeStyles(theme => ({
  dyContainer: {
    width: "100%",
    padding: "0em 2em",
    fontSize: "1.0em",
    marginLeft: "-1px",
    borderLeft: "1px solid #00000045",
    "& h2": {
      fontWeight: 600,
      fontSize: "1.2em",
      paddingBottom: "10px"
    }
  }
}));
