import React, { useEffect } from "react";
import LineGraph from "./LineGraph/LineGraph";
import GraphParse from "./GraphParse";
import ChoroplethParent from "./ChoroplethMap/ChoroplethParent";
import "./scss/lineGraphButton.scss";
import { useDispatch, useSelector } from "react-redux";
import { buttonsAction } from "../Components/redux-actions/buttonsToShow";

const DisplaySelected = ({
  data,
  filters,
  setOpen,
  queryType,
  setChartDataSM,
  uniquePhones
}) => {
  const dispatch = useDispatch();
  const openGraph = useSelector(state => state.selectedGraphReducer.graph);
  useEffect(() => {
    dispatch(buttonsAction(filters));
  }, [filters]);
  const renderLine = () => {
    let additionalFiltersArray = [];
    for (let key in filters) {
      if (key > 1) {
        let cat = filters[key].selectedCategory;
        let selected = "";
        let obj = filters[key].selectableOptions;
        for (let item in obj) {
          if (obj[item] === true) {
            selected = item;
            additionalFiltersArray.push(`${cat}: ${selected}`);
          }
        }
      }
    }
    if (openGraph === "line") {
      return (
        <>
          <LineGraph
            filter0={filters[0]}
            data={data}
            additionalFiltersArray={additionalFiltersArray}
            uniquePhones={uniquePhones}
          />
        </>
      );
    } else {
    }
  };

  const renderChoroplethMap = () => {
    if (openGraph === "choropleth") {
      return (
        <>
          <ChoroplethParent
            gqlData={data}
            filters={filters}
            queryType={queryType}
          />
        </>
      );
    } else {
    }
  };

  const renderBar = () => {
    if (openGraph === "bar") {
      return (
        <>
          <GraphParse
            data={data}
            filters={filters}
            queryType={queryType}
            setChartDataSM={setChartDataSM}
            uniquePhones={uniquePhones}
          />
        </>
      );
    } else if (openGraph !== "bar") {
    }
  };

  if (filters[0]["selectedCategory"] === "Traders' Destination Country") {
    return (
      <>
        {renderLine()}
        {renderBar()}
        {renderChoroplethMap()}
      </>
    );
  } else if (
    filters[0]["selectedCategory"] ===
    "Relevant Trade Procedures, by Destination"
  ) {
    return (
      <>
        {renderLine()}
        {renderBar()}
        {renderChoroplethMap()}
      </>
    );
  } else if (
    openGraph === "choropleth" &&
    filters[0]["selectedCategory"] !== "Country of Residence" &&
    filters[0]["selectedCategory"] !== "Traders' Destination Country" &&
    filters[0]["selectedCategory"] !==
      "Relevant Trade Procedures, by Destination"
  ) {
    setOpen("bar");
    return <>{renderBar()}</>;
  } else if (filters[0].selectedTable === "Sessions") {
    return (
      <>
        {renderBar()}
        {renderLine()}
      </>
    );
  } else if (
    data &&
    data.tradersUsers &&
    filters[0]["selectedCategory"] === "Country of Residence"
  ) {
    return (
      <>
        {renderBar()}
        {renderChoroplethMap()}
      </>
    );
  } else {
    return <>{renderBar()}</>;
  }
};

export default DisplaySelected;
