import React from "react";

const SearchSVG = ({
  style = {},
  fill = "#F5AF37",
  width = "5rem",
  viewBox = "0 0 33 32"
}) => (
  <svg width={width} style={style} height={width} viewBox={viewBox}>
    <g>
      <path
        fill={fill}
        d="M1.5 32h21c0.827 0 1.5-0.673 1.5-1.5v-7.5c0-0.010-0.005-0.018-0.006-0.028 0.055-0.035 0.112-0.068 0.166-0.105l7.986 7.986c0.098 0.098 0.226 0.147 0.354 0.147s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-7.913-7.913c1.264-1.187 2.059-2.868 2.059-4.734 0-2.297-1.201-4.316-3.006-5.472 0.001-0.010 0.006-0.018 0.006-0.028v-2.5c0-0.017-0.008-0.031-0.009-0.047-0.002-0.023-0.008-0.043-0.013-0.065-0.017-0.071-0.046-0.135-0.090-0.191-0.007-0.009-0.006-0.020-0.013-0.029l-8-9c-0.003-0.003-0.007-0.003-0.010-0.006-0.060-0.065-0.137-0.108-0.223-0.134-0.019-0.006-0.036-0.008-0.056-0.011-0.029-0.005-0.056-0.017-0.086-0.017h-14c-0.827 0-1.5 0.673-1.5 1.5v29c0 0.827 0.673 1.5 1.5 1.5zM26 17.5c0 3.033-2.468 5.5-5.5 5.5s-5.5-2.467-5.5-5.5 2.468-5.5 5.5-5.5 5.5 2.467 5.5 5.5zM16 1.815l6.387 7.185h-5.887c-0.22 0-0.5-0.42-0.5-0.75v-6.435zM1 1.5c0-0.276 0.225-0.5 0.5-0.5h13.5v7.25c0 0.809 0.655 1.75 1.5 1.75h6.5v1.501c-0.77-0.322-1.614-0.501-2.5-0.501-1.838 0-3.496 0.77-4.679 2h-10.321c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h9.532c-0.565 0.879-0.922 1.901-1.006 3h-8.526c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h8.525c0.084 1.099 0.442 2.121 1.006 3h-9.531c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h10.25c0.021 0 0.039-0.009 0.060-0.012 1.184 1.237 2.847 2.012 4.69 2.012 0.886 0 1.73-0.179 2.5-0.502v7.002c0 0.276-0.225 0.5-0.5 0.5h-21c-0.28 0-0.5-0.22-0.5-0.5v-29zM5.5 10h6c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-6c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5zM5.5 26h13c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-13c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5z"
      ></path>
    </g>
  </svg>
);

export default SearchSVG;
