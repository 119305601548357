import React from "react";

const UndrawInvestmentSVG = ({
  //   style = { marginRight: "1rem" },
  //   fill = "#038C5A",
  width = "100%",
  height = "100%",
  viewBox = "0 0 888 572.01129"
}) => (
  <svg
    id="b56ce292-c2ef-482a-b84e-c8d73988b32f"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <title>investment_data</title>
    <rect x="39.5" y="318.70885" width="6" height="5" fill="#e6e6e6" />
    <path
      d="M769.0293,487.70321H757.20605v-5H769.0293Zm-23.64649,0H733.55859v-5h11.82422Zm-23.64746,0H709.91211v-5h11.82324Zm-23.64648,0H686.26465v-5h11.82422Zm-23.64746,0H662.61816v-5h11.82325Zm-23.64746,0H638.9707v-5H650.794Zm-23.64649,0H615.32422v-5h11.82324Zm-23.64746,0H591.67676v-5H603.5Zm-23.647,0H568.02979v-5H579.853Zm-23.647,0H544.38232v-5h11.82373Zm-23.647,0H520.73535v-5h11.82373Zm-23.647,0H497.08838v-5h11.82373Zm-23.647,0H473.44141v-5h11.82373Zm-23.64746,0H449.79443v-5h11.82325Zm-23.647,0H426.14746v-5H437.9707Zm-23.647,0H402.5v-5h11.82373Zm-23.647,0H378.853v-5h11.82373Zm-23.647,0H355.20605v-5h11.82374Zm-23.64747,0H331.55908v-5h11.82324Zm-23.647,0H307.91211v-5h11.82324Zm-23.647,0H284.26514v-5h11.82324Zm-23.647,0H260.61768v-5h11.82373Zm-23.647,0H236.9707v-5h11.82373Zm-23.647,0H213.32373v-5h11.82373Z"
      transform="translate(-156 -163.99436)"
      fill="#e6e6e6"
    />
    <rect x="39.5" y="191.70885" width="6" height="5" fill="#e6e6e6" />
    <path
      d="M769.0293,360.70321H757.20605v-5H769.0293Zm-23.64649,0H733.55859v-5h11.82422Zm-23.64746,0H709.91211v-5h11.82324Zm-23.64648,0H686.26465v-5h11.82422Zm-23.64746,0H662.61816v-5h11.82325Zm-23.64746,0H638.9707v-5H650.794Zm-23.64649,0H615.32422v-5h11.82324Zm-23.64746,0H591.67676v-5H603.5Zm-23.647,0H568.02979v-5H579.853Zm-23.647,0H544.38232v-5h11.82373Zm-23.647,0H520.73535v-5h11.82373Zm-23.647,0H497.08838v-5h11.82373Zm-23.647,0H473.44141v-5h11.82373Zm-23.64746,0H449.79443v-5h11.82325Zm-23.647,0H426.14746v-5H437.9707Zm-23.647,0H402.5v-5h11.82373Zm-23.647,0H378.853v-5h11.82373Zm-23.647,0H355.20605v-5h11.82374Zm-23.64747,0H331.55908v-5h11.82324Zm-23.647,0H307.91211v-5h11.82324Zm-23.647,0H284.26514v-5h11.82324Zm-23.647,0H260.61768v-5h11.82373Zm-23.647,0H236.9707v-5h11.82373Zm-23.647,0H213.32373v-5h11.82373Z"
      transform="translate(-156 -163.99436)"
      fill="#e6e6e6"
    />
    <rect x="39.5" y="64.70885" width="6" height="5" fill="#e6e6e6" />
    <path
      d="M769.0293,233.70321H757.20605v-5H769.0293Zm-23.64649,0H733.55859v-5h11.82422Zm-23.64746,0H709.91211v-5h11.82324Zm-23.64648,0H686.26465v-5h11.82422Zm-23.64746,0H662.61816v-5h11.82325Zm-23.64746,0H638.9707v-5H650.794Zm-23.64649,0H615.32422v-5h11.82324Zm-23.64746,0H591.67676v-5H603.5Zm-23.647,0H568.02979v-5H579.853Zm-23.647,0H544.38232v-5h11.82373Zm-23.647,0H520.73535v-5h11.82373Zm-23.647,0H497.08838v-5h11.82373Zm-23.647,0H473.44141v-5h11.82373Zm-23.64746,0H449.79443v-5h11.82325Zm-23.647,0H426.14746v-5H437.9707Zm-23.647,0H402.5v-5h11.82373Zm-23.647,0H378.853v-5h11.82373Zm-23.647,0H355.20605v-5h11.82374Zm-23.64747,0H331.55908v-5h11.82324Zm-23.647,0H307.91211v-5h11.82324Zm-23.647,0H284.26514v-5h11.82324Zm-23.647,0H260.61768v-5h11.82373Zm-23.647,0H236.9707v-5h11.82373Zm-23.647,0H213.32373v-5h11.82373Z"
      transform="translate(-156 -163.99436)"
      fill="#e6e6e6"
    />
    <rect x="186" y="320.20885" width="49" height="180" fill="#3f3d56" />
    <rect x="195" y="320.20885" width="49" height="180" fill="#eb5e52" />
    <rect x="302" y="193.20885" width="49" height="307" fill="#3f3d56" />
    <rect x="311" y="193.20885" width="49" height="307" fill="#eb5e52" />
    <rect x="418" y="66.20885" width="49" height="434" fill="#3f3d56" />
    <rect x="427" y="66.20885" width="49" height="434" fill="#eb5e52" />
    <circle cx="40" cy="67.20885" r="12" fill="#e6e6e6" />
    <circle cx="40" cy="194.20885" r="12" fill="#e6e6e6" />
    <circle cx="40" cy="321.20885" r="12" fill="#e6e6e6" />
    <rect y="499.08849" width="888" height="2.24072" fill="#3f3d56" />
    <circle cx="756.34414" cy="59.79509" r="34.03721" fill="#2f2e41" />
    <path
      d="M830.0109,447.79106l-1.83985,11.0391s-10.11917,37.7169,2.75977,35.877,8.27932-34.0372,8.27932-34.0372L841.05,450.55084Z"
      transform="translate(-156 -163.99436)"
      fill="#ffb9b9"
    />
    <path
      d="M943.62157,395.81533H869.10769s-21.15827,33.11728-12.879,91.07252,4.59963,187.6646,4.59963,187.6646H889.346l19.31841-203.30331,40.47668,203.30331,26.67781-2.75977L961.10014,437.21193Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <path
      d="M868.64773,671.33271l-.91993,34.03721s-11.959,34.03721,5.51955,30.35751,19.31841-11.0391,19.31841-13.79887-4.59962-50.59585-4.59962-50.59585Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <path
      d="M971.14429,668.74665l5.26653,36.39965s16.21493,32.22771-1.59075,30.81417-20.57186-8.47712-20.9249-11.21421-1.91058-53.52833-1.91058-53.52833Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <circle cx="756.8041" cy="68.53437" r="24.83796" fill="#ffb9b9" />
    <polygon
      points="775.663 79.114 785.782 114.071 765.543 134.309 754.504 114.991 750.825 87.393 775.663 79.114"
      fill="#ffb9b9"
    />
    <path
      d="M940.40184,266.56594s-1.35444-9.27857-4.35692-8.319-29.68029,7.39906-29.68029,10.15884v11.959l-22.07819,85.553-5.51955,34.95713,57.95525-.91992,9.19924-121.43Z"
      transform="translate(-156 -163.99436)"
      fill="#e6e6e6"
    />
    <path
      d="M935.34225,256.90673l6.43947,1.83985,10.57913,10.57913s25.75789-4.59962,29.43759,7.3594S961.5601,437.67189,961.5601,437.67189s-40.47668,13.79887-46.91615-21.15826-5.97951-64.85468,6.89943-86.93287S935.34225,256.90673,935.34225,256.90673Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <path
      d="M908.65508,265.11583l-3.67034,1.91007L901.765,281.28473s-26.67781,7.3594-28.51766,17.47856.91992,65.31464.91992,65.31464-25.75788,52.4357-21.15826,74.51389c0,0,11.03909,9.19924,19.31841-2.75978s28.51766-83.71312,29.43759-88.31275S912.78538,266.4255,908.65508,265.11583Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <polygon
      points="727.367 131.089 717.516 133.76 710.808 153.167 697.009 202.843 670.331 284.717 688.73 292.996 718.167 233.201 727.367 131.089"
      fill="#2f2e41"
    />
    <path
      d="M931.66255,229.309c-15.81558-5.7046-21.16736-9.20908-38.63683,0l-6.43947-25.75789H938.102Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <circle cx="753.58437" cy="23.91804" r="19.31841" fill="#2f2e41" />
    <path
      d="M886.58625,183.31277a19.30231,19.30231,0,0,1,18.39849-19.272c-.307-.01449-.60931-.04643-.91992-.04643a19.31841,19.31841,0,1,0,0,38.63682c.31061,0,.61291-.03194.91992-.04643A19.30234,19.30234,0,0,1,886.58625,183.31277Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <rect
      x="715.62535"
      y="287.10712"
      width="71.75411"
      height="30.53366"
      fill="#eb5e52"
    />
    <rect
      x="715.62535"
      y="287.10712"
      width="71.75411"
      height="30.53366"
      opacity="0.1"
    />
    <circle cx="751.5024" cy="302.37395" r="8.39676" opacity="0.2" />
    <polygon
      points="732.419 315.351 718.679 315.351 718.679 289.397 733.182 289.397 733.182 290.924 720.205 290.924 720.205 313.824 732.419 313.824 732.419 315.351"
      opacity="0.2"
    />
    <polygon
      points="784.326 315.351 770.586 315.351 770.586 313.824 782.799 313.824 782.799 290.924 769.823 290.924 769.823 289.397 784.326 289.397 784.326 315.351"
      opacity="0.2"
    />
    <rect
      x="868.86557"
      y="463.98042"
      width="71.75411"
      height="30.53366"
      transform="translate(-257.72229 127.46174) rotate(-17.34142)"
      fill="#eb5e52"
    />
    <circle cx="748.74263" cy="315.25289" r="8.39676" opacity="0.2" />
    <polygon
      points="734.394 333.328 721.279 337.423 713.543 312.65 727.387 308.327 727.842 309.784 715.455 313.652 722.281 335.511 733.939 331.871 734.394 333.328"
      opacity="0.2"
    />
    <polygon
      points="783.942 317.856 770.827 321.952 770.372 320.494 782.03 316.854 775.204 294.995 762.817 298.863 762.362 297.405 776.206 293.082 783.942 317.856"
      opacity="0.2"
    />
    <path
      d="M943.29584,432.56629l-4.21112,10.36885s-11.1221,37.43342-21.13233,29.12394S928.71587,438.724,928.71587,438.724l3.72955-9.585Z"
      transform="translate(-156 -163.99436)"
      fill="#ffb9b9"
    />
    <path
      d="M968.9195,279.44488l12.87894-2.75977a134.88963,134.88963,0,0,1,4.43947,16.55864c1.83985,9.19924-1,81.87328-2.83985,89.23267s-35.19924,56.39472-35.19924,56.39472l-30.02069-6.51348,43.382-65.51995Z"
      transform="translate(-156 -163.99436)"
      fill="#2f2e41"
    />
    <polygon
      points="806 162.209 798 206.209 768 267.209 795 203.209 806 162.209"
      opacity="0.2"
    />
  </svg>
);

export default UndrawInvestmentSVG;
