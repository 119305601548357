import { SELECTEDGRAPH } from "../redux-actions/selectedGraphAction";

const initialState = {
  graph: "bar"
};

const selectedGraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTEDGRAPH:
      return {
        ...state,
        graph: action.payload
      };
    default:
      return state;
  }
};

export default selectedGraphReducer;
