import React from "react";
import { topChecked, sumAll, allBoxes } from "../LineGraphHelpers/topChecked";
import { hundredScale } from "../LineGraphHelpers/scale100";
import { quickSort } from "../LineGraphHelpers/Range";
import { combineAmountsToQtr } from "./lineHelpers/quarter";
import { useHistory } from "react-router-dom";
import LineTime from "./LineTime";
import startEndDates from "./lineHelpers/startEndDates";

const LineGraph = ({ filter0, data, additionalFiltersArray, uniquePhones }) => {
  const history = useHistory();
  const url = history.location.search;
  const { startYear, endYear } = url ? startEndDates(url) : null;
  let lineNonNull = [...data.tradersUsers];
  //get option selected from the first filter
  const selectedTableColumnName = filter0.selectedTableColumnName;

  const totalByTimeframe = (objectArray, ColumnName) => {
    return objectArray.reduce(function(total, obj) {
      if (!total.monthAmts) total.monthAmts = {};
      if (!total.quarterAmts) total.quarterAmts = {};
      if (!total.yearAmts) total.yearAmts = {};
      //combine date and cat type to make a new key
      let keyMo = obj["created_mo"] + obj[ColumnName];
      let keyYear = obj["created_year"] + obj[ColumnName];
      let keyQtr = obj["created_qtr"] + obj[ColumnName];

      if (!total?.monthAmts[keyMo]) {
        total.monthAmts[keyMo] = obj.sum;
      }
      total.monthAmts[keyMo] = total.monthAmts[keyMo] + obj.sum;

      if (!total.yearAmts[keyYear]) {
        total.yearAmts[keyYear] = obj.sum;
      }
      total.yearAmts[keyYear] = total.yearAmts[keyYear] + obj.sum;

      if (!total.quarterAmts[keyQtr]) {
        total.quarterAmts[keyQtr] = obj.sum;
      }
      total.quarterAmts[keyQtr] = total.quarterAmts[keyQtr] + obj.sum;
      return total;
    }, {});
  };

  const { monthAmts, quarterAmts, yearAmts } = totalByTimeframe(
    lineNonNull,
    selectedTableColumnName
  );

  const top7keys = topChecked(
    lineNonNull,
    selectedTableColumnName,
    startYear,
    endYear
  );
  //a checkbox is created for each category that has more than 10 data points

  const checkboxes = allBoxes(sumAll);

  //By year-month
  let dateCatArray = [];
  let yearCatArray = [];

  //let objectCombined = {};
  function combineAmountsToDates(o) {
    for (let key of Object.keys(o)) {
      let yearMo = key.slice(0, 7);
      let cat = key.slice(7, 100);
      let obj = {};
      obj["date"] = yearMo;
      obj[cat] = o[key];
      dateCatArray.push(obj);
    }
    quickSort(dateCatArray, 0, dateCatArray.length - 1);
  }
  let monthlyDates = { ...monthAmts };
  combineAmountsToDates(monthlyDates);

  let dateCatQtr = combineAmountsToQtr(quarterAmts);

  //Yearly
  // let objectCombined = {};
  function combineAmountsToYear(o) {
    for (let key of Object.keys(o)) {
      let year = key.slice(0, 4);
      let cat = key.slice(4, 100);
      let obj = {};
      obj["date"] = year;
      obj[cat] = o[key];
      yearCatArray.push(obj);
      // }
    }
    quickSort(yearCatArray, 0, yearCatArray.length - 1);
  }

  combineAmountsToYear(yearAmts);

  //6. combine together to create object for Monthly data
  let usedDates = [];
  let itemDate = {};
  let allCombined = [];
  for (let i = 0; i < dateCatArray.length; i++) {
    let date = dateCatArray[i].date;
    if (usedDates.includes(date)) {
      itemDate = {
        ...itemDate,
        ...dateCatArray[i]
      };
      allCombined.push(itemDate);
    } else {
      let arraykeys = Object.keys(dateCatArray[i]);
      let arrayValues = Object.values(dateCatArray[i]);
      let newDate = {};
      newDate["date"] = date;
      newDate[arraykeys[1]] = arrayValues[1];
      itemDate = newDate;
      usedDates.push(date);
      allCombined.push(itemDate);
    }
  }

  //6.a. combine together to create object for Yearly data
  let usedYears = [];
  let itemYear = {};
  let allCombinedYears = [];
  for (let i = 0; i < yearCatArray.length; i++) {
    let date = yearCatArray[i].date;
    if (usedYears.includes(date)) {
      itemYear = {
        ...itemYear,
        ...yearCatArray[i]
      };
      allCombinedYears.push(itemYear);
    } else {
      let arraykeys = Object.keys(yearCatArray[i]);
      let arrayValues = Object.values(yearCatArray[i]);
      let newDate = {};
      newDate["date"] = date;
      newDate[arraykeys[1]] = arrayValues[1];
      itemYear = newDate;
      usedYears.push(date);
      allCombinedYears.push(itemYear);
    }
  }

  //Update Monthly
  let updated = [];
  for (let i = 0; i < allCombined.length; i++) {
    if (
      i + 1 < allCombined.length &&
      allCombined[i].date !== allCombined[i + 1].date
    ) {
      updated.push(allCombined[i]);
    } else if (i + 1 === allCombined.length) {
      updated.push(allCombined[i]);
    }
  }
  //Update Yearly
  let updatedYearly = [];
  for (let i = 0; i < allCombinedYears.length; i++) {
    if (
      i + 1 < allCombinedYears.length &&
      allCombinedYears[i].date !== allCombinedYears[i + 1].date
    ) {
      updatedYearly.push(allCombinedYears[i]);
    } else if (i + 1 === allCombinedYears.length) {
      updatedYearly.push(allCombinedYears[i]);
    }
  }

  const yearAll = hundredScale(updatedYearly);
  const year100 = yearAll.array;

  const monthAll = hundredScale(updated);
  const month100 = monthAll.array;

  //Quarterly
  const byQuarter = [...lineNonNull];

  //3. Put categories together by Quarter
  const catByQtr = (objectArray, property, property1) => {
    return objectArray.reduce(function(total, obj) {
      let key = obj[property] + obj[property1];
      //combine date and cat type to make a new key

      //make a new object if the year-mo and category not existing
      if (!total[key]) {
        total[key] = [];
      }
      //if year-qtr, then push obj
      total[key].push(obj);
      return total;
    }, {});
  };

  let groupedItems = catByQtr(
    byQuarter,
    "created_qtr",
    selectedTableColumnName
  );

  // 4.  get total amount per item in each quarter
  // map through obj and get length of arrays
  let qtrAmounts = {};
  function mapObjAny(mapper, o, label) {
    for (let key of Object.keys(o)) {
      label[key] = mapper(o[key]);
    }
  }

  // call function, variables 1. function, 2.array of objects 3.New empty object
  mapObjAny(
    function sumUp(val) {
      let sumAll = 0;
      for (let i = 0; i < val.length; i++) {
        sumAll += val[i].sum;
      }
      return sumAll;
    },
    groupedItems,
    qtrAmounts
  );

  //6. combine together to create object for Monthly data
  let usedDatesQtr = [];
  let itemDateQtr = {};
  let allCombinedQtr = [];
  for (let i = 0; i < dateCatQtr.length; i++) {
    let date = dateCatQtr[i].date;

    if (usedDatesQtr.includes(date)) {
      itemDateQtr = {
        ...itemDateQtr,
        ...dateCatQtr[i]
      };
      allCombinedQtr.push(itemDateQtr);
    } else {
      let arraykeys = Object.keys(dateCatQtr[i]);
      let arrayValues = Object.values(dateCatQtr[i]);

      let newDate = {};
      newDate["date"] = date;
      newDate[arraykeys[1]] = arrayValues[1];

      itemDateQtr = newDate;
      usedDatesQtr.push(date);
      allCombinedQtr.push(itemDateQtr);
    }
  }

  //6. update array
  const updatedQtr = [];
  for (let i = 0; i < allCombinedQtr.length; i++) {
    if (
      i + 1 < allCombinedQtr.length &&
      allCombinedQtr[i].date !== allCombinedQtr[i + 1].date
    ) {
      updatedQtr.push(allCombinedQtr[i]);
    } else if (i + 1 === allCombinedQtr.length) {
      updatedQtr.push(allCombinedQtr[i]);
    }
  }

  const quarterAll = hundredScale(updatedQtr);
  const quarter100 = quarterAll.array;

  let top7 = {};
  for (let i = 0; i < top7keys.length; i++) {
    let obj = {};
    obj[top7keys[i]] = true;
    top7 = { ...top7, ...obj };
  }

  return (
    <>
      <LineTime
        month100={month100}
        quarter100={quarter100}
        year100={year100}
        top7={top7}
        checkboxes={checkboxes}
        filter0={filter0}
        additionalFiltersArray={additionalFiltersArray}
        uniquePhones={uniquePhones}
      />
    </>
  );
};

export default LineGraph;
