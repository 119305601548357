import { getSelectedOption } from "../../../OptionFunctions";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedGraphAction } from "../../redux-actions/selectedGraphAction";
import { translateUrl } from "../../../utils/categories/FilterBoxOptions";

const useCreateSearchParams = (filters, urlSearchParams, updateUrlFlag) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const graphSelected = useSelector(state => state.selectedGraphReducer.graph);
  const { filterBoxStartDate, filterBoxEndDate } = useSelector(
    state => state.calendarReducer.calendar
  );
  const graphTypes = ["bar", "line", "choropleth"];
  const updateUrl = () => {
    Object.keys(filters).forEach(filterId => {
      let options = "";
      if (getSelectedOption(filters, filterId) !== undefined) {
        options = `${getSelectedOption(filters, filterId)}`;
      }
      if (Number(filterId) <= 1 && filters[filterId].selectedTableColumnName) {
        urlSearchParams["filter" + String(filterId)] =
          translateUrl[`${filters[filterId].selectedTableColumnName}`];
      }
      if (Number(filterId) > 1 && filters[filterId].selectedTableColumnName) {
        urlSearchParams["filter" + String(filterId)] =
          translateUrl[`${filters[filterId].selectedTableColumnName}`];
        urlSearchParams[`filter${filterId}val`] = options;
      }
      if (graphSelected) {
        if (graphSelected !== "bar" && filters[0].selectedTable === "Users") {
          dispatch(selectedGraphAction("bar"));
        } else if (
          filters[0].selectedTable === "Sessions" &&
          !graphTypes.includes(graphSelected)
        ) {
          dispatch(selectedGraphAction("bar"));
        } else {
          urlSearchParams["graph"] = graphSelected;
        }
      } else {
        urlSearchParams["graph"] = "bar";
        dispatch(selectedGraphAction("bar"));
      }
      if (
        filters[0].selectedTable === "Sessions" &&
        filterBoxStartDate &&
        filterBoxEndDate
      ) {
        urlSearchParams["start"] = filterBoxStartDate;
        urlSearchParams["end"] = filterBoxEndDate;
      }
    });
    return urlSearchParams;
  };
  useEffect(() => {
    const urlSearchParams = updateUrl();
    const filterStrings = createUrl(urlSearchParams);
    history.push("?" + filterStrings);
  }, [updateUrlFlag, graphSelected, filterBoxStartDate, filterBoxEndDate]);
};

const createUrl = urlSearchParams => {
  let keys = Object.keys(urlSearchParams);
  let values = Object.values(urlSearchParams).map(value =>
    encodeURIComponent(value)
  );
  const filterStrings = keys.map((key, i) => key + "=" + values[i]).join("&");
  return filterStrings;
};

export { useCreateSearchParams, createUrl };
