import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { makeStyles } from "@material-ui/core/styles";
import { numberWithComma } from "../../utils/categories/translateSql";

const TableDisplay = ({
  makeValuesRedux,
  columnsRedux,
  lineTable,
  setLineTable
}) => {
  const classes = useStyles();
  const [columns, setColoumns] = useState([
    {
      Header: "Column 1",
      accessor: "col1"
    }
  ]);
  const [data, setData] = useState([
    {
      col1: "Hello",
      col2: "World"
    }
  ]);

  useEffect(() => {
    if (makeValuesRedux[0][0] === "date") {
      for (let i = 0; i < makeValuesRedux[0].length; i++) {
        let obj = {};
        obj["id"] = i;
        obj["displayName"] = makeValuesRedux[0][i];
        if (makeValuesRedux[0][i] !== "date") {
          columnsRedux.push(obj);
        }
      }
      makeValuesRedux.shift();
      setLineTable(true);
    }
    columnsTable(columnsRedux);
    rowsTable(makeValuesRedux);
  }, [columnsRedux, makeValuesRedux]);

  const columnsTable = columnsRedux => {
    let arrColumns = [];
    let sampleSize;
    for (let i = 0; i < columnsRedux?.length; i++) {
      const words = ["Size:", "Users:", "Filters"];
      const foundHeader = new RegExp(words.join("|")).test(
        columnsRedux[i].displayName
      );
      if (foundHeader) {
        let splitArr = columnsRedux[i].displayName.split(" ");
        let arrConverted = [];
        let withComma;
        for (let i = 0; i < splitArr.length; i++) {
          if (
            /\d/.test(splitArr[i]) &&
            !splitArr[i].includes(",") &&
            !splitArr[i].includes("Age")
          ) {
            let commaNum = numberWithComma(Number(splitArr[i]));
            arrConverted.push(commaNum);
          } else {
            arrConverted.push(splitArr[i]);
          }
          withComma = arrConverted.join(" ");
        }
        sampleSize = withComma ? withComma : columnsRedux[i].displayName;
      } else if (typeof columnsRedux[i].displayName !== "object") {
        arrColumns.push({
          Header: columnsRedux[i].displayName,
          accessor: `col${columnsRedux[i].id}`
        });
      }
    }
    const headerColumns = [
      {
        Header: sampleSize,
        columns: arrColumns
      }
    ];
    setColoumns(headerColumns);
  };

  const rowsTable = makeValuesRedux => {
    let rowsArray = [];
    for (let i = 0; i < makeValuesRedux?.length; i++) {
      let row = makeValuesRedux[i];
      let obj = {};

      for (let i = 0; i < row.length; i++) {
        let value = row[i];
        const regExp = /[a-zA-Z]/g;
        value = value.toString();
        if (
          /\d/.test(value) &&
          !value.includes("-") &&
          !value.includes("<") &&
          !regExp.test(value)
        )
          value = numberWithComma(value);
        obj[`col${i}`] = value;
      }
      rowsArray.push(obj);
    }
    setData(rowsArray);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });
  return (
    <div className={lineTable ? classes.modalLine : classes.modal}>
      <table
        {...getTableProps()}
        style={{
          borderSpacing: 0,
          border: "solid 1px"
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className={lineTable ? classes.headerLine : classes.headerBar}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={
                        lineTable ? classes.lineStyle : classes.barTable
                      }
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default TableDisplay;

const useStyles = makeStyles(theme => ({
  barTable: {
    padding: "15px",
    border: "solid 1px gray",
    background: "white"
  },
  headerBar: {
    border: "solid 1px gray",
    borderBottom: "solid 3px 546e7a",
    background: "aliceblue",
    color: "black",
    fontWeight: "bold",
    padding: "3px"
  },
  headerLine: {
    border: "solid 1px black",
    borderBottom: "solid 1px 546e7a",
    background: "aliceblue",
    color: "black",
    fontWeight: "bold",
    paddingTop: ".5rem",
    fontSize: "10px",
    margin: "auto"
  },
  modal: {
    display: "flex",
    alignItems: "flexStart",
    justifyContent: "center",
    overflowY: "scroll",
    maxHeight: "80%",
    margin: "auto"
  },
  modalLine: {
    display: "flex",
    alignItems: "flexStart",
    justifyContent: "flexStart",
    overflowY: "scroll",
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "auto"
  },
  lineStyle: {
    border: "solid 1px gray",
    background: "white",

    paddingLeft: ".5rem",
    margin: 0
  }
}));
