import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import TableChartIcon from "@material-ui/icons/TableChart";
import { makeStyles } from "@material-ui/core/styles";
import TableDisplay from "./TableDisplay";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";

const TableButton = ({
  track,
  makeValuesRedux,
  columnsRedux,
  fileName,
  suffix
}) => {
  const [open, setOpen] = useState();
  const [lineTable, setLineTable] = useState();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title="Table Display"
        arrow
        classes={{ tooltip: classes.customWidth }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <TableChartIcon className={classes.socialMediaLink}></TableChartIcon>
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={lineTable ? classes.modalLine : classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <TableDisplay
            track={track}
            makeValuesRedux={makeValuesRedux}
            columnsRedux={columnsRedux}
            fileName={fileName}
            suffix={suffix}
            setOpen={setOpen}
            lineTable={lineTable}
            setLineTable={setLineTable}
          />
        </Fade>
      </Modal>
    </>
  );
};
export default TableButton;

const useStyles = makeStyles(theme => ({
  customWidth: {
    fontSize: "16px"
  },
  modal: {
    display: "flex",
    alignItems: "flexStart",
    justifyContent: "center",
    maxHeight: "80%",
    margin: "auto"
  },
  modalLine: {
    display: "flex",
    alignItems: "flexStart",
    justifyContent: "flexStart",
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "auto"
  },
  socialMediaLink: {
    fontSize: "2.5rem",
    color: "rgb(159, 28, 15)",
    cursor: "pointer",
    opacity: "0.75",
    "&:hover": {
      opacity: 1
    }
  }
}));
