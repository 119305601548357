import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { NavLink, Route, useHistory, withRouter } from "react-router-dom";
import { HistoryListen, PageView } from "./GoogleAnalytics/index";
import UsersQuery from "./Admin/UsersQuery";
import AccountHandler from "../dashboard/DashboardAccount/AccountHandler";
import NoAccount from "../dashboard/DashboardAccount/NoAccount";
import SignUp from "./SignUp";
import LandingPage from "./LandingPage";
import QueryGraphLabels from "./QueryGraphLabels";
import DashData from "./DashData";
import DashLogout from "./DashLogout";
import Login from "./Login";
import ProtectedRoute from "./auth/ProtectedRoute";
import CodeRoute from "./DashboardAccount/PasswordReset/auth/CodeRoute";
import { getToken, decodeToken } from "./auth/Auth";
import UserSVG from "./Images/UserSVG";
import ForgotPassword from "./DashboardAccount/PasswordReset/ForgotPassword";
import ResetPasswordContainer from "./DashboardAccount/PasswordReset/ResetPasswordContainer";
import LandingParent from "../Components/themeLanding/LandingParent";
import Pricing from "../Components/themePricing/Pricing";
import Contact from "../Components/themeContact/Contact";
import About from "../Components/themeAbout/About";
import Faqs from "../Components/themeFAQs/Faqs";
import { useSelector } from "react-redux";
import VerifyEmailContainer from "./DashboardAccount/VerifyEmail/VerifyEmailContainer";

import Image from "../../src/Components/themeStyledComponents/atoms/Image/";
import imgLogo from "../../src/assets/images/web-logo-light.png";
import {
  TopBar,
  SautiLogo,
  SautiLogoText,
  SautiDot,
  Navigation,
  Links,
  Container
} from "./styledComponents/Index";
import { gql } from "apollo-boost";
import axios from "axios";
import { useMutation } from "@apollo/react-hooks";

const ADD_USERINFO = gql`
  mutation onUserInfo($addUserIpVar: newUserInfo!) {
    userIpInfo(input: $addUserIpVar) {
      ... on UserInfoType {
        IPv4
        city
        country_code
        latitude
        longitude
        pagetitle
        date
      }
      ... on Error {
        message
      }
    }
  }
`;

function DashNav() {
  const [ip, setIp] = useState();
  const [page, setPage] = useState();
  const [userIp, { loading, error }] = useMutation(ADD_USERINFO);
  HistoryListen();
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      if (!ip) {
        getData().then(obj => {
          setIp(obj);
          setPage(history.location.pathname);
          let userObj = obj;
          userObj["pagetitle"] = pageTitles[history.location.pathname];
          addUserIp(userObj);
        });
      } else if (page !== history.location.pathname) {
        setPage(history.location.pathname);
        let userObj = ip;
        userObj["pagetitle"] = pageTitles[history.location.pathname];
        if (userObj["pagetitle"]) addUserIp(userObj);
      }
    }
  }, [history.location.pathname]);

  const pageTitles = {
    "/": "Data on East Africa's Traders - Sauti Trade Insights",
    "/data": "Data - Sauti Trade Insights",
    "/myaccount": "Account - Sauti Trade Insights",
    "/about": "About - Sauti Trade Insights",
    "/contact": "Contact - Sauti Trade Insights",
    "/faqs": "FAQs - Sauti Trade Insights",
    "/pricing": "Pricing - Sauti Trade Insights"
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let data = res.data;
    let obj = {};
    obj["country_code"] = data.country_code;
    obj["latitude"] = String(data.latitude);
    obj["longitude"] = String(data.longitude);
    obj["IPv4"] = data.IPv4;
    setIp(obj);
    return obj;
  };

  useEffect(() => {
    PageView();
  });

  const addUserIp = async userObj => {
    const addedUserIp = await userIp({
      variables: {
        addUserIpVar: userObj
      }
    });
  };

  const SignedIn = getToken();
  const token = getToken();
  let email;
  let tier;
  if (token) {
    let tokenDecoded = decodeToken(token);
    email = tokenDecoded.email;
    tier = tokenDecoded.tier;
  }

  const labels = useSelector(state => state.catLabelReducer.labels);
  let labelsRedux = false;
  if (labels) {
    labelsRedux = labels.getGraphLabels;
  }

  // const isLandingPage = window.location.href ? "http://localhost:3000/" : null;

  return (
    <>
      <Container>
        <TopBar LandingPage={LandingPage.props}>
          <SautiLogo>
            <ReactGA.OutboundLink
              style={{ textDecoration: "none" }}
              eventLabel="Outbound Link to http://sautiafrica.org/"
              to="http://sautiafrica.org/"
            >
              <Image
                src={imgLogo}
                style={{ marginTop: "4px" }}
                alt="CBT Insights by Sauti East Africa"
              />
            </ReactGA.OutboundLink>
          </SautiLogo>
          <Navigation>
            <Links to="/">HOME</Links>
            <Links to="/data">DATA</Links>
            <Links to="/about">ABOUT</Links>
            <Links to="/faqs">FAQs</Links>
            {SignedIn && <Links to="/myaccount">ACCOUNT</Links>}
            {!SignedIn && <Links to="/pricing">PRICING</Links>}
            <Links to="/contact">CONTACT</Links>
            {!SignedIn && <Links to="/login">LOGIN</Links>}
            {tier === "ADMIN" && <Links to="/admin">ADMIN</Links>}
            {SignedIn && <Links to="/logout">LOGOUT</Links>}
            {SignedIn && (
              <div className="loggedInAs">
                <UserSVG />
                <span className="email">{email}</span>
              </div>
            )}
          </Navigation>
        </TopBar>
      </Container>
      <Route exact path="/" component={LandingParent} />
      <Route exact path="/pricing" component={Pricing} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/faqs" component={Faqs} />
      <Route
        exact
        path="/data"
        component={labelsRedux ? DashData : QueryGraphLabels}
      />
      <Route exact path="/admin" component={UsersQuery} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/passwordreset" component={ForgotPassword} />
      <Route exact path="/noaccount" component={NoAccount} />
      <ProtectedRoute exact path="/myaccount" component={AccountHandler} />
      <ProtectedRoute exact path="/logout" component={DashLogout} />
      <CodeRoute
        exact
        path="/password-verification"
        component={ResetPasswordContainer}
      />
      <CodeRoute
        exact
        path="/email-verification"
        component={VerifyEmailContainer}
      />
    </>
  );
}

export default withRouter(DashNav);
