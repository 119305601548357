import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import ValueNotSelectedModal from "./ValueNotSelectedModal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { dateAction } from "../redux-actions/dateAction";

const ADD_SEARCH = gql`
  mutation onSearchHistory($addSearchVar: newSearchHistory!) {
    searchHistory(input: $addSearchVar) {
      ... on SearchType {
        user_id
        series
        filters
        compare
      }
      ... on Error {
        message
      }
    }
  }
`;

const Apply = ({ handleApply, filters, setFilters }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [noValue, setNoValue] = useState();
  const [search, { loading, error }] = useMutation(ADD_SEARCH);
  const dispatch = useDispatch();

  let applyFromDash = false;
  applyFromDash = useSelector(state => state.applyActionReducer.apply.apply);
  const clearApply = useSelector(state => state.clearApplyReducer.clear.clear);
  const id = useSelector(state => state.tierReducer.id);

  const confirmCatAndValue = () => {
    for (let item in filters) {
      if (item > 1 && filters[item].selectedCategory) {
        if (!Object.values(filters[item]["selectableOptions"]).includes(true)) {
          return filters[item].selectedCategory;
        }
      }
    }
  };

  useEffect(() => {
    setShow(true);
  }, [filters, applyFromDash]);

  const addSearch = async () => {
    let seriesValue = "";
    let filtersValues = {};
    let compareValue = "";
    for (let item in filters) {
      item = Number(item);
      if (item === 0) seriesValue = filters[0]["selectedCategory"];
      if (item === 1) compareValue = filters[1]["selectedCategory"];
      if (item > 1) {
        for (let k in filters[item]["selectableOptions"]) {
          if (filters[item]["selectableOptions"][k]) {
            if (filtersValues[filters[item]["selectedCategory"]]) {
              if (
                typeof filtersValues[filters[item]["selectedCategory"]] ===
                "string"
              ) {
                filtersValues[filters[item]["selectedCategory"]] = [
                  filtersValues[filters[item]["selectedCategory"]],
                  k
                ];
              } else {
                filtersValues[filters[item]["selectedCategory"]].push(k);
              }
            } else {
              filtersValues[filters[item]["selectedCategory"]] = k;
            }
          }
        }
      }
    }
    filtersValues = JSON.stringify(filtersValues);
    const input = {
      user_id: id,
      series: seriesValue,
      filters: filtersValues,
      compare: compareValue
    };
    const addedSearch = await search({
      variables: {
        addSearchVar: input
      }
    });
  };

  function handleClick() {
    addSearch();
    if (confirmCatAndValue()) {
      setNoValue(confirmCatAndValue());
    } else {
      handleApply();
      setShow(false);
      dispatch(dateAction(true));
    }
  }

  if (noValue) {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={ValueNotSelectedModal}
          onClose={() => setNoValue()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade>
            <ValueNotSelectedModal
              noValue={noValue}
              setNoValue={setNoValue}
              filters={filters}
              setFilters={setFilters}
            />
          </Fade>
        </Modal>
      </>
    );
  }

  if (show && applyFromDash !== true && clearApply !== true) {
    return (
      <>
        <Grid item xs={6}>
          <Tooltip
            title="Press Apply"
            open
            arrow
            classes={{ tooltip: classes.customWidth }}
          >
            <button className={classes.applyButton} onClick={handleClick}>
              Apply
            </button>
          </Tooltip>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
};
export default Apply;

const useStyles = makeStyles(theme => ({
  applyButton: {
    border: "2px solid #9F1C0F",
    backgroundColor: "#9F1C0F",
    color: "#FFF",
    height: "3rem",
    fontWeight: "500",
    fontSize: "1.5rem",
    padding: "0% 12%",
    borderRadius: ".5rem",
    cursor: "pointer",
    float: "right"
  },
  customWidth: {
    fontSize: "16px"
  }
}));
