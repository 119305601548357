import abbreviateLabels from "./abbreviateLabels";

//Builds data for Nivo when single filtering by "Most Requested"
const getMostRequested = (data, dataStructure, indexBy) => {
  dataStructure = dataStructure.filter(obj => obj[`${indexBy}`] !== null);
  // This block of code transforms from raw numbers to percentages
  let sampleSize = 0;

  dataStructure.map(item => {
    let keyValue = item[`${indexBy}`];
    return (sampleSize += Number(item[keyValue]));
  });

  // CSV KEYS ARE FOR DOWNLOADABLE DATA
  const csvKeys = dataStructure.map(obj => obj[`${indexBy}`]);
  let percentageData = dataStructure.map(obj => Object.assign({}, obj));

  percentageData.forEach(obj => {
    const keyValue = obj[`${indexBy}`];
    obj[keyValue] = (obj[keyValue] / sampleSize) * 100;
  });

  // dataStructure used for csv, percentage for graph
  // sort data from
  percentageData = percentageData.sort((a, b) =>
    Object.values(a)[1] > Object.values(b)[1] ? -1 : 1
  );
  dataStructure = dataStructure.sort((a, b) =>
    Object.values(a)[1] > Object.values(b)[1] ? -1 : 1
  );

  let combinedNondisplayedEntries = percentageData.slice(
    6,
    percentageData.length - 1
  );
  let count = 0;

  combinedNondisplayedEntries.forEach(obj => {
    let tempVar = obj[`${indexBy}`];
    count += +obj[tempVar];
  });

  percentageData = percentageData.slice(0, 6);

  percentageData.forEach(obj => {
    let tempVar = obj[`${indexBy}`];
    obj[tempVar] = obj[tempVar].toFixed(0);
  });

  if (count > 0) {
    percentageData.push({ [indexBy]: "Other", Other: count.toFixed(0) });
  }

  const keys = percentageData.map(obj => obj[`${indexBy}`]);

  //Function abbreviates graph labels
  if (
    indexBy === "procedurerelevantagency" ||
    indexBy === "procedurerequireddocument" ||
    indexBy === "procedurecommodity" ||
    indexBy === "procedureorigin"
  ) {
    abbreviateLabels(percentageData, indexBy);
  }

  return {
    dataStructure,
    keys: keys.reverse(),
    csvKeys,
    indexBy,
    sampleSize,
    percentageData
  };
};

export default getMostRequested;
