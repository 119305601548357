import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Admin from "./Admin";
import Loader from "react-loader-spinner";

const Users_Query = gql`
  query UsersQ {
    allUsers: databankUsers {
      id
      email
      interest
      tier
      organization
      job_position
      country
      organization_type
      registration_date
      found_by
      p_next_billing_time
      paypal_plan
    }
  }
`;

const UsersQuery = () => {
  const { data, loading, error } = useQuery(Users_Query);
  console.log(data?.allUsers);
  //must enter loading
  if (loading) {
    return (
      <div className="loader-container">
        <Loader
          className="loader"
          type="Oval"
          color="#708090"
          width={100}
          timeout={100000}
        />
      </div>
    );
  }

  if (error) {
    return <p>error</p>;
  }

  data.allUsers.map(item => {
    if (item.p_next_billing_time !== null) {
      let convertBill = new Date(Number(item.p_next_billing_time));
      convertBill = convertBill.toDateString().slice(4, 15);
      item.p_next_billing_time = convertBill;
    }
  });

  data.allUsers.map(item => {
    let mydate = new Date(Number(item.registration_date));
    let local = mydate.toLocaleDateString("sv");
    item.reg_date = local;
  });

  return (
    <>
      <Admin allUsers={data.allUsers} />
    </>
  );
};
export default UsersQuery;
