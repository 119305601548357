const labelsHardCode = {
  age: ["<21", "21-30", "31-40", "41-50", "51-60", "61-70"],
  country_of_residence: ["KEN", "RWA", "TZA", "UGA"],
  crossing_freq: ["Never", "Daily", "Weekly", "Monthly"]
};

const corArray = [
  { country_of_residence: "KEN", sum: 0, __typename: "TraderUser" },
  { country_of_residence: "RWA", sum: 0, __typename: "TraderUser" },
  { country_of_residence: "TZA", sum: 0, __typename: "TraderUser" },
  { country_of_residence: "UGA", sum: 0, __typename: "TraderUser" }
];

export { labelsHardCode, corArray };
